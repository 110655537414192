// Dependencies
import './menu.js'
import 'what-input'
import LazyLoad from 'vanilla-lazyload'
import flatpickr from 'flatpickr'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat.js'

// Configure cookie bar
window.fubracookiebar_options = {
  message: 'This website uses cookies to ensure you get the best experience - by continuing to use our site, you are agreeing to our <a href="/main/cookie-policy.html">use of cookies</a>.',
  button: 'Accept and Close'
}

// Set dayjs format to match flatpickr
const dayjsFormat = 'DD-MM-YY'

// Allow dayjs to parse custom format
dayjs.extend(customParseFormat)

// Dom ready
util.docReady( () => {

  // Start lazy loading
  const lazyLoadInstance = new LazyLoad({
    elements_selector: '.lazy'
  })

  // Remove any FOUC classes as soon as foundation is ready
  document.querySelectorAll('.fouc').forEach( el => {
    el.classList.remove('fouc')
  })

  // Show any promo banners after 3 secs
  const promoBanner = document.getElementById('promo-banner')
  if (promoBanner) {
    promoBanner.style.display = 'block'
    setTimeout(() => {
      promoBanner.classList.remove('preshow')
    }, 3000 ) 
  }

  // Enable basic accordians
  document.querySelectorAll('.accordion-item').forEach( node => {
    node.querySelector('.accordion-title').addEventListener('click', function (e) {
      e.preventDefault()
      node.querySelector('.accordion-content').classList.toggle('show')
      return false 
    })
  })
  
  // Cached elements
  const arrivalNodes        = document.querySelectorAll('.datepicker.arrivalDate')
       ,arrivalTimeNodes    = document.querySelectorAll('.timepicker.arrivalTime')
       ,returnNodes         = document.querySelectorAll('.datepicker.returnDate')
       ,returnTimeNodes     = document.querySelectorAll('.timepicker.returnTime')
       ,errEl               = document.querySelectorAll('.quote-error')
       ,airport_term_el     = document.querySelectorAll('.airport-terminal-quoteform')
       ,airport_w_terminal  = document.querySelectorAll('select.with-terminal')
       ,airport_wo_terminal = document.querySelectorAll('select.without-terminal')

  // Watch for airport changes
  document.querySelectorAll('[name="airport"]').forEach( el => {
    el.addEventListener( 'change', function(e) {

      // Get airport value and terminal value
      let airport_terminal_val = this.value.split('-')

      let airport_val
      let terminal_val

      if( !this.value.startsWith('SINGLE-') ) {

        airport_val = airport_terminal_val[0]
        terminal_val = airport_terminal_val[1]

        // Check if a terminal exists for this airport
        if ( !terminal_val && window.terminals[airport_val] ) terminal_val = 'ALL'

        // Update all other forms on the page also
        airport_w_terminal.forEach( el => {
          el.value = (terminal_val ? airport_val + '-' + terminal_val : airport_val)
        })
        airport_wo_terminal.forEach( el => {
          el.value = airport_val
        })

        // Refresh terminals if exists
        if ( ! airport_term_el.length ) return
      
      } else {
        airport_val = this.value
        terminal_val = ''
      }

      // Update terminal value
      updateTerminal(airport_val, terminal_val)

      // Check errors
      checkErrors()

    })
  })

  // Watch for terminal changes
  document.querySelectorAll('[name="terminal"]').forEach( el => {
    el.addEventListener( 'change', e => {

      // Get target
      let targetEl = e.target || e.srcElement

      if (airport_w_terminal.length) {
        let airport_val = airport_w_terminal[0].value.split('-')[0]
        airport_w_terminal.forEach( el => {
          el.value = airport_val + '-' + targetEl.value
        })
      }

    })
  })

  /**
   * Update dynamic terminal dropdown in quote forms
   */
  const updateTerminal = (airport_val, terminal_val) => {

    if( terminal_val ) {

      // Create terminals div if set
      let t_options = '<option value="ALL">All Terminals</option>'
      for( let terminal_code in window.terminals[airport_val] ) {
        t_options += '<option value="' + terminal_code + '">' + window.terminals[airport_val][terminal_code] + '</option>'
      }

      // Update terminals
      airport_term_el.forEach( el => {
        el.querySelector('#terminals').innerHTML = t_options
        el.querySelector('#terminals').value = terminal_val
      })

      airport_term_el.forEach( el => {
        el.querySelector('#div_terminal').classList.remove('hide')
      })

      airport_term_el.forEach( el => {
        el.querySelector('#div_airport').classList.add('medium-7')
      })

    } else {

      airport_term_el.forEach( el => {
        el.querySelector('[name="terminal"]').innerHTML = ''
        el.querySelector('[name="terminal"]').value = ''
      })

      airport_term_el.forEach( el => {
        el.querySelector('#div_terminal').classList.add('hide')
      })

      airport_term_el.forEach( el => {
        el.querySelector('#div_airport').classList.remove('medium-7')
      })

    }
  }
  
  /**
   * Check errors when submitting form
   */
  const checkErrors = (e = null) => {

    // Get airport, arrival date and return date
    let arrivalDate = dayjs( arrivalNodes[0].value, dayjsFormat ).unix()
    let returnDate = dayjs( returnNodes[0].value, dayjsFormat ).unix()
    let airport = document.querySelector('select#airports')

    // Check times is set
    if( !arrivalTimeNodes[0].value || !returnTimeNodes[0].value || !arrivalNodes[0].value || !returnNodes[0].value) {

      // Stop submit
      e.preventDefault()

      // Display error and stop submit
      errEl.forEach( el => {
        el.innerText = 'Please correct all date and time fields'
      })
      errEl.forEach( el => {
        el.classList.remove('hide')
      })
      return false
    }

    // Check arrival is prior to return date
    if( arrivalDate > returnDate ) {

      // Stop submit
      if(e) e.preventDefault()

      // Display error and stop submit
      errEl.forEach( el => {
        el.innerText = 'Please select a return date which is after the arrival date'
      })
      errEl.forEach( el => {
        el.classList.remove('hide')
      })
      return false
    }

    // Check aiport is selected
    if ( !airport.value || airport.value === 'ALL' ) {

      // Stop submit
      if(e) e.preventDefault()

      // Display error and stop submit
      errEl.forEach( el => {
        el.innerText = 'Please select an Airport'
      })
      errEl.forEach( el => {
        el.classList.remove('hide')
      })
      return false
    }

    // If carpark specific, run carpark quote
    if ( airport.value.startsWith('SINGLE-') && e && window.carpark_quote ) {
      return window.carpark_quote(e)
    }

    // Clear error
    errEl.forEach( el => {
      el.innerText = ''
      el.classList.add('hide')
    })
  }

  /**
   * Create flatpickr pickers
   */
  const createPickers = () => {

    /**
     * Local function for updating pickers
     * @param {jQuery selector} el 
     * @param {dayjs} date 
     */
    let updateNodes =  ( nodes, date ) => {
      let isoDate = date.toISOString()
      nodes.forEach( el => el._flatpickr.setDate( isoDate, false, 'Z' ) )
    }

    // Init Flatpickr
    document.querySelectorAll('.datepicker').forEach( ( el, i ) => {

      flatpickr( el, {
        altInput: true,
        altFormat: "j F, Y",
        dateFormat: 'd-m-y',
        minDate: "today",
        static: el.classList.contains('datepicker-static'),
        defaultDate: dayjs().add( 2, 'day' ).format(dayjsFormat),
        onChange: (selectedDates, dateStr, instance) => {

          // Get current date dayjs object
          let newDate = dayjs( instance.input.value, dayjsFormat )

          // Update arrival pickers when changed
          if ( instance.input.classList.contains('arrivalDate') ) {

            // Update all other arrival dates
            updateNodes( arrivalNodes, newDate )

            // If return has not been updated, add 7days
            if ( !returnNodes[0].classList.contains('dirty') ) {

              // Create new return date
              let newReturnDate = newDate.add( 7, 'day' )

              // Update all return dates
              updateNodes( returnNodes, newReturnDate )
            }

          // Update returnDate pickers when changed
          } else if ( instance.input.classList.contains('returnDate') ) {

              // Mark picker as dirty - stop adding 7 days when arrival changes
              returnNodes[0].classList.add('dirty')

              // Update all return dates
              updateNodes( returnNodes, newDate )
          }
        }
      })
    })

    // Check if dates are overriden
    if (window.aps_default_dates) {

      let defaultArrivalDate = dayjs( window.aps_default_dates.arrivalDate )
      updateNodes( arrivalNodes, defaultArrivalDate )

      let defaultReturnDate = dayjs( window.aps_default_dates.returnDate )
      updateNodes( returnNodes, defaultReturnDate )

      arrivalTimeNodes[0].value = window.aps_default_dates.arrivalTime
      returnTimeNodes[0].value = window.aps_default_dates.returnTime
      
    } else {

      // Update default return dates
      let defaultReturnDate = dayjs().add( 9, 'day' )
      updateNodes( returnNodes, defaultReturnDate )
    }
  }

  /**
   * Update timepickers
   */
  arrivalTimeNodes.forEach( el => {
    el.addEventListener( 'change', e => {

      // Get target
      let targetEl = e.target || e.srcElement

      arrivalTimeNodes.value = targetEl.value
    })
  })

  returnTimeNodes.forEach( el => {
    el.addEventListener( 'change', e => {

      // Get target
      let targetEl = e.target || e.srcElement

      returnTimeNodes.value = targetEl.value
    })
  })


  // Check if airport can be set
  if (window.aps_airport_id) {

    // Split form vals in case of terminal
    let apt_form_vals = window.aps_airport_id.toString().split('-')
    if ( apt_form_vals.length === 1 && window.terminals[window.aps_airport_id] ) apt_form_vals[1] = 'ALL'

    // Set joint forms
    airport_w_terminal.forEach( el => {
      el.value = apt_form_vals[0] + (apt_form_vals[1] ? '-' + apt_form_vals[1] : '')
    })

    // Set split forms
    airport_wo_terminal.forEach( el => {
      el.value = apt_form_vals[0]
    })

    // Update form terminal
    updateTerminal( apt_form_vals[0], apt_form_vals[1] )
  }

  // Init pickers
  createPickers()

  // Attach error checking to quote form submission
  document.querySelectorAll('.quoteform form').forEach( el => {
    el.addEventListener('submit', checkErrors)
  })

  // Attach handler for trigerring carpark quote form submit
  const carparkQuoteTrigger = document.querySelectorAll('.trigger-carpark-quote')
  if (carparkQuoteTrigger) {
    carparkQuoteTrigger.forEach( node => {
      node.addEventListener('click', e => {
        const domEvent = document.createEvent('Event')
        domEvent.initEvent('submit', false, true)
        document.querySelector('.carpark_form').dispatchEvent(domEvent)
      })
    })
  }

})
