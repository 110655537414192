
// Show menu on mobile
const menuIcon = document.querySelector('header.top-bar button.menu-icon')
if (menuIcon) {
  menuIcon.addEventListener( 'click', e => {

    e.preventDefault()
    document.getElementById('top-menu').classList.toggle('show-for-large')
    return false
  }) 
}

// Hide all top-level dropdowns
let hideDropdowns = () => {
  document.querySelectorAll('ul.dropdown').forEach( node => {
    node.style.maxHeight = ''
    node.classList.remove('show')
    node.removeAttribute('aria-expanded')
  })
  document.querySelectorAll('li.menu.active').forEach( node => {
    node.classList.remove('active')
  })
}

// Hide all sub-level dropdowns
let hideRegionDropdowns = () => {
  document.querySelectorAll('ul.region-dropdown').forEach( node => {
    node.style.maxHeight = ''
    node.removeAttribute('aria-expanded')
  })
  document.querySelectorAll('li.region-menu.active').forEach( node => {
    node.classList.remove('active')
  })
}

// Show dropdowns
document.querySelectorAll('li.menu a, li.region-menu a').forEach( node => {
  node.addEventListener( 'click', function(e) {

    let parent = this.parentNode

    // Get clicked menu item's immediatte dropdown
    let dropdown = parent.querySelector('ul.region-dropdown, ul.dropdown')

    // If this doesn't contain a dropdown (final link) stop
    if ( !dropdown ) return true

    // Stop bubble
    e.stopPropagation()
    e.preventDefault()

    // Check if clicked item is open
    if ( parent.classList.contains('active') ) {
      
      // Check what type of open dropdown was clicked
      if (parent.classList.contains('region-menu')) hideRegionDropdowns()
      else if (parent.classList.contains('menu')) hideDropdowns()
    
    // Clicked closed item
    } else {

      // Hide other open dropdowns
      hideRegionDropdowns()

      // Only hide top-level dropdown if this isn't one
      if( !parent.classList.contains('region-menu') ) hideDropdowns()

      // Set "active" classes
      parent.classList.add('active')
      dropdown.classList.add('show')

      // Calculate consitent max-height (est to keep animation consistent)
      dropdown.style.maxHeight = (dropdown.children.length * 100) + 'px'

      // Screen reader support
      dropdown.setAttribute('aria-expanded', 'true')
    }

    // Prevent final navigation
    return false
  })
})

// Hide dropdowns when clicking "html"
document.querySelector('html').addEventListener( 'click', e => {
  hideDropdowns()
  hideRegionDropdowns()
})
